import React from "react";
import { ToastContainer } from "@welog/component-library";
import { wrapRootElement as apolloWrapRootElement } from "./src/apollo/provider";
import { ApolloProvider } from "@apollo/client";
import { ApolloProviderProps } from "@apollo/client/react/context";

/**
 * Wrap whole application with Apollo context provider and add {@link ToastContainer} for displaying messages
 * @function
 * @param element {React.ReactNode} wrapped Element
 * @param props {ApolloProviderProps} additional props for {@link ApolloProvider}
 * @returns {React.ReactNode}
 */
export const wrapRootElement = ({ element, props }) => {
  return apolloWrapRootElement({
    element: (
      <>
        {element}
        <ToastContainer />
      </>
    ),
    props,
  });
};

export default {};
