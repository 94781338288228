import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import fetch from "isomorphic-fetch";
import { t } from "i18next";
import { toast } from "@welog/component-library";
import { getJWT, isLoggedIn, logout } from "../services/auth/auth";
import { navigate } from "gatsby";
import { ApolloProviderProps } from "@apollo/client/react/context";

/** @module apollo/provider */

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  if (isLoggedIn()) {
    const token = getJWT();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }
  return {};
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache",
    errorPolicy: "ignore",
  },
  mutation: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (process.env.NODE_ENV === "development") {
      console.log("graphQLErrors", graphQLErrors);
    }
  }
  if (networkError) {
    if (networkError.statusCode === 401) {
      logout();
      navigate("/");
    }
    if (process.env.NODE_ENV === "development") {
      console.log("networkError", networkError);
    }
    toast(
      t("common.networkError.title"),
      t("common.networkError.body"),
      "error",
      { toastId: "network-error" }
    );
  }
});

const link = ApolloLink.from([
  errorLink,
  authLink.concat(
    createUploadLink({
      uri: process.env.GATSBY_DYNAMIC_GRAPHQL,
      fetch,
    })
  ),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions,
});

/**
 * Wrap element with Apollo context provider.
 * Uses JWT to authenticate to the server ({@link module:services/auth/auth.getJWT|getJWT}).
 * Deletes {@link module:services/auth/auth.logout|JWT} and navigates the browser to <code>/</code> when server returns 401 (Unauthorized).
 * Displays toast messages when network errors happen.
 * @memberof module:apollo/provider
 * @function
 * @param element {React.ReactNode} wrapped element
 * @param props {ApolloProviderProps} additional props for {@link ApolloProvider} without <code>client</code>
 * @returns {React.ReactElement}
 */
export const wrapRootElement = ({ element, props }) => {
  return (
    <ApolloProvider client={client} {...props}>
      {element}
    </ApolloProvider>
  );
};

/**
 * @memberof module:apollo/provider
 * @function default
 * see {@link module:module:apollo/provider.wrapRootElement|wrapRootElement}
 */
export default wrapRootElement;
