import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

/** @module services/auth/auth */

/**
 * Check if running in browser
 * @memberof module:services/auth/auth
 * @function
 * @return {boolean}
 */
export const isBrowser = () => true;

/**
 * Read user info from JWT
 * @memberof module:services/auth/auth
 * @function
 * @returns {Object} decoded JWT or empty object
 * see {@link module:services/auth/auth.getJWT}
 */
export const getUser = () => {
  if (isBrowser() && Cookies.get("token")) {
    const rawUser = Cookies.get("token");
    return jwtDecode(rawUser);
  }
  return {};
};

/**
 * Get stored JWT (from cookie <code>token</code>)
 * @memberof module:services/auth/auth
 * @function
 * @returns {string} JWT or empty
 */
export const getJWT = () => {
  if (isBrowser() && Cookies.get("token")) {
    if (Cookies.get("token") !== undefined) {
      return Cookies.get("token");
    }
  }
  return "";
};

/**
 * Check if user is logged in
 * @memberof module:services/auth/auth
 * @function
 * @return {boolean} JWT is available
 * see {@link module:services/auth/auth.getJWT}
 */
export const isLoggedIn = () => {
  const jwt = getJWT();
  if (jwt !== "") {
    const userJWT = jwtDecode(jwt);
    return !!userJWT.exp;
  }
  return false;
};

/**
 * Log out (by deleting stored JWT)
 * @memberof module:services/auth/auth
 * @function
 * see {@link module:services/auth/auth.getJWT}
 */
export const logout = () => {
  Cookies.remove("token");
};
