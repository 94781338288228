import * as Sentry from "@sentry/gatsby";
import { BrowserTracing } from "@sentry/gatsby";

Sentry.init({
  dsn: "https://e546882ebcd446208264db92b5c0bb31@o318311.ingest.sentry.io/4505028653875200",
  integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
