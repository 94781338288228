exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-api-delete-js": () => import("./../../../src/pages/apiDelete.js" /* webpackChunkName: "component---src-pages-api-delete-js" */),
  "component---src-pages-api-reset-is-edited-js": () => import("./../../../src/pages/apiResetIsEdited.js" /* webpackChunkName: "component---src-pages-api-reset-is-edited-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-google-auth-callback-js": () => import("./../../../src/pages/googleAuthCallback.js" /* webpackChunkName: "component---src-pages-google-auth-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microsoft-auth-callback-js": () => import("./../../../src/pages/microsoftAuthCallback.js" /* webpackChunkName: "component---src-pages-microsoft-auth-callback-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */)
}

